const projects = [
  {
    title: 'Afroic',
    description: ['A collection of discussion forums for Africa with a dedicated forum for each nation.'],
    link: 'https://afroic.com',
    image: 'images/afroic-preview.png',
    technologies: [
      { name: 'React', image: 'images/technologies/react.png' },
      { name: 'JavaScript', image: 'images/technologies/javascript.png' },
      { name: 'jQuery', image: 'images/technologies/jquery.png' },
      { name: 'Ruby on Rails', image: 'images/technologies/ruby-on-rails.png' },
      { name: 'PostgreSQL', image: 'images/technologies/postgresql.png' }
    ]
  },
  {
    title: 'Shoppawatch',
    description: ['An ecommerce application for selling watches.'],
    link: 'https://shoppawatch.com',
    githubLink: 'https://github.com/priscilla-codes/shoppawatch',
    image: 'images/shoppawatch-preview.png',
    technologies: [
      { name: 'React', image: 'images/technologies/react.png' },
      { name: 'Redux', image: 'images/technologies/redux.png' },
      { name: 'Ruby on Rails', image: 'images/technologies/ruby-on-rails.png' },
      { name: 'PostgreSQL', image: 'images/technologies/postgresql.png' }
    ]
  },
  {
    title: 'Pokedexelus',
    description: ['An application that allows users to search for and favorite Pokemon.',
    "Example search term: 'mega'"
  ],
    link: 'https://pokedexelus.netlify.app/',
    githubLink: 'https://github.com/priscilla-codes/pokedexelus',
    image: 'images/pokedexelus-preview.png',
    classNameName: 'pokedexelus',
    technologies: [
      { name: 'React', image: 'images/technologies/react.png' },
      { name: 'Redux', image: 'images/technologies/redux.png' },
      { name: 'HTML', image: 'images/technologies/html.png' },
      { name: 'CSS', image: 'images/technologies/css.png' }
      
    ]
  },
  {
    title: 'Hired',
    description: ["A mockup of the sign in page for hired.com's website"],
    link: 'https://hired-sign-in-clone.netlify.app/',
    githubLink: 'https://github.com/priscilla-codes/hired',
    image: 'images/hired-preview.png',
    technologies: [
      { name: 'React', image: 'images/technologies/react.png' },
      { name: 'HTML', image: 'images/technologies/html.png' },
      { name: 'CSS', image: 'images/technologies/css.png' }
    ]
  }
];

const Projects = () => {
  return (
    <div id="my-work" className="projects-section">
      <h2 className="name">
        <span className="long-dash"></span>
        <span className="name-text">My work</span>
      </h2>
      <div className="projects-preview">
      {projects.map((project, index) => (
        <div className="project">
          <h2>{project.title}</h2>
          <a href={project.link} target="_blank" rel="noopener noreferrer">
            <img src={project.image} alt={project.title} />
          </a>
          <div class="project-description">
            {project.description.map((sentence, i) => (
              <p key={i}>{sentence}</p>
            ))}
          </div>
          <div class="project-links-wrapper">
            <div class="project-links">
              <span><a href={project.link} target="_blank">Live</a></span>
              {project.githubLink && (
                <span>
                  <a href={project.githubLink} target="_blank" rel="noopener noreferrer">
                    Github
                  </a>
                </span>
              )}
              <span>
              <a href={`/${project.image}`} target="_blank" rel="noopener noreferrer">
                Image
              </a>
              </span>
            </div>
          </div>
          <div className="project-technologies">
            <ul>
              {project.technologies.map((tech, i) => (
                <li key={i}>
                  <img src={`/${tech.image}`} alt={tech.name} />
                  <span>{tech.name}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
      </div>
    </div>
  );
};

export default Projects;